import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { Link } from 'gatsby'

import tacoGIF from '../assets/images/taco.gif'
import coding from '../assets/images/coding.png'
import fire from '../assets/images/fire.png'
import responsive from '../assets/images/responsive.png'

const About = props => (
  <Layout>
    <Helmet>
      <title>about me</title>
      <meta name="description" content="About me" />
    </Helmet>

    <div id="main">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>About me</h1>
          </header>
          <section id="projects" className="spotlights">
            <section className="watashi">
              <div className="profile">
                <img
                  className="gif"
                  src={tacoGIF}
                  width="100%"
                  height="70%"
                  alt="tacoscoffee"
                />
              </div>
              <div className="description">
                <div className="innerin">
                  <h3 className="tae">Hello. I'm Tae Kimura.</h3>
                  <p className="setumei">
                    I'm a front-end developer with 3 years of experience that
                    specializes in JavaScript, and web frameworks
                    (React/Next.js).
                    <br />
                    <br />I have a passion for web development and love to learn
                    new technologies. I look forward to joining a team that
                    shares my passion for coding and will continue to push me to
                    reach new heights.
                  </p>
                  <br />
                </div>
              </div>
            </section>
            <br /> <br />
            <section className="spotlights">
              <div className="inner2">
                <header className="major">
                  <h1 className="myskills">My Skills</h1>
                </header>
                <p>
                  Through my work experience, I've gained a understanding of web
                  development concepts, and have dedicated a lot of my free time
                  to apply these concepts to real-world scenarios and
                  applications.
                </p>
                <br />
                <div className="fadein pages__SkillTable-sc-6kvjaa-1 cgIyGY">
                  <row>
                    <p class="Paragraph-sc-1tzuhix-0 cjRxGx">
                      <span className="maru">●</span> Javascript
                    </p>
                    <p class="Paragraph-sc-1tzuhix-0 cjRxGx">
                      <span className="maru">●</span> PHP
                    </p>
                  </row>
                  <row>
                    <p class="Paragraph-sc-1tzuhix-0 cjRxGx">
                      <span className="maru">●</span> React
                    </p>
                    <p class="Paragraph-sc-1tzuhix-0 cjRxGx">
                      <span className="maru">●</span> HTML &amp; CSS
                    </p>
                  </row>
                  <row>
                    <p class="Paragraph-sc-1tzuhix-0 cjRxGx">
                      <span className="maru">●</span> Next.js
                    </p>
                    <p class="Paragraph-sc-1tzuhix-0 cjRxGx">
                      <span className="maru">●</span> Sass
                    </p>
                  </row>
                  <row>
                    <p class="Paragraph-sc-1tzuhix-0 cjRxGx">
                      <span className="maru">●</span> TypeScript
                    </p>
                    <p class="Paragraph-sc-1tzuhix-0 cjRxGx">
                      <span className="maru">●</span> WordPress
                    </p>
                  </row>
                </div>
                <br />
                <br />
                <br />
                <br />
              </div>
            </section>
            <header className="major">
              <h1>My Values</h1>
            </header>
            <div class="contents__passion__wrap">
              <div class="contents__passion fadein">
                <img src={coding} width="100px" height="100px" alt="icon eye" />
                <p class="passion__title">Web development</p>
                <p class="passion__text">
                  Developing pixel perfect design and code for websites. This
                  includes hand crafted websites from top to bottom &amp;
                  websites built around a CMS.
                </p>
              </div>
              <div class="contents__passion fadein">
                <img
                  src={responsive}
                  width="100px"
                  height="100px"
                  alt="icon eye"
                />
                <p class="passion__title">Responsive</p>
                <p class="passion__text">
                  All websites are designed &amp; coded with responsive in mind.
                  Product will look &amp; function just as well on desktop,
                  tablet, mobile devices... or anything else in between.
                </p>
              </div>
              <div class="contents__passion fadein">
                <img src={fire} width="100px" height="100px" alt="icon eye" />
                <p class="passion__title">Passion for coding</p>
                <p class="passion__text">
                  I'm passionate about building compelling websites. I'm always
                  punctual and a great team player. I never stop learning and
                  keep improving with up-to-date skills.
                </p>
              </div>
            </div>
            <br />
            <ul className="actions">
              <li>
                <Link to="/#projects" className="button next">
                  Go to projects page
                </Link>
              </li>
            </ul>
            <br />
          </section>
        </div>
      </section>
    </div>
  </Layout>
)

export default About
